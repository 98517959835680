<script>
/**
 * Widget Component
 */

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import appConfig from "@/app.config";
import moment from "moment";

export default {
  props: ["telecallers"],
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      filters: {
        project: null,
        startDate: null,
        endDate: null,
        productCallStatus: null,
        speciality: null,
        country: null,
        state: null,
        city: null,
        opted_in: 0,
        daterange: null,
        telecaller: null,
        call_statuses: null,
      },
      projects: [],
      specialities: [],
      states: [],
      cities: [],
      statuses : [],
      countries: ["India"],
      isHidden: false,
      clicked: false,
    };
  },
  methods: {
    async getProjects() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/projects"
        );
        this.projects = (response.data.telecaller_projects).filter(f => [116,115,159].includes(f.id));
      } catch (err) {
        console.log(err);
      }
    },
    async getSpecialities() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/specialities"
        );
        this.specialities = response.data.specialities;
      } catch (err) {
        console.log(err);
      }
    },
    async getCallStatuses(){
      try {
        if(this.$store.state.authfack.user.role == 'PharmaIndustry') {
          const response = await this.$http.get(
            appConfig.api_base_url + "/telecallers/callSubStatuses"
          );
          this.statuses = response.data.call_statuses;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/telecallers/callStatuses"
          );
          this.statuses = response.data.call_statuses;
        }
      }catch (err) {
        console.log(err);
      }
    },
    async loadFilters() {
      this.getProjects();
      this.getSpecialities();
      this.getStates(this.countries);
      this.getCallStatuses();
    },
    async getStates(countries) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/states",
          {
            params: {
              countries: countries,
            },
          }
        );
        this.states = response.data.states;
      } catch (err) {
        console.log(err);
      }
    },
    async getCities(states) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/cities",
          {
            params: {
              states: states.map((obj) => obj.name),
            },
          }
        );
        this.cities = response.data.cities;
      } catch (err) {
        console.log(err);
      }
    },
    getFormatedDate(date) {
      try {
        return moment(date).format("yyyy-MM-DD");
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    getParams(params) {
      let project_ids = this.filters.project
        ? this.filters.project.map((obj) => obj.id)
        : {};
      if (project_ids && project_ids.length > 0) {
        params.project_ids = project_ids;
      }
      let specialities = this.filters.speciality
        ? this.filters.speciality.map((obj) => obj.title)
        : {};
      if (specialities && specialities.length > 0) {
        params.specialities = specialities;
      }

      let opted_in = this.filters.opted_in ? this.filters.opted_in : null;
      if (opted_in && opted_in != null) {
        params.opted_in = this.filters.opted_in ? this.filters.opted_in : false;
      }

      let from_date =
        this.filters.daterange && this.filters.daterange.length > 0 &&
      this.filters.daterange[0] && this.filters.daterange[0] != null
          ? this.getFormatedDate(this.filters.daterange[0])
          : null;
      if (from_date) {
        params.from_date = from_date;
      }
      let to_date =
        this.filters.daterange &&
        this.filters.daterange.length > 0 &&
        this.filters.daterange[1] && this.filters.daterange[1] != null
          ? this.getFormatedDate(this.filters.daterange[1])
          : null;
      if (to_date) {
        params.to_date = to_date;
      }

      let states = this.filters.state
        ? this.filters.state.map((obj) => obj.name)
        : {};
      if (states && states.length > 0) {
        params.states = states;
      }

      let cities = this.filters.city
        ? this.filters.city.map((obj) => obj.name)
        : {};
      if (cities && cities.length > 0) {
        params.cities = cities;
      }

      let digimrs = this.filters.telecaller
        ? this.filters.telecaller.map((obj) => obj.id)
        : {};
      if (digimrs && digimrs.length > 0) {
        params.telecaller_ids = digimrs;
      }
      if(this.filters.call_statuses != null){
        let call_statuses = this.filters.call_statuses.map(s => s.title);
        if(call_statuses.length > 0){
          params.call_statuses = call_statuses;
        }
      }
      this.params = {};
      this.params = params;
      return params;
    },
    added(){
      this.isClicked = !this.isClicked;
      this.isHidden = !this.isHidden;
    }
  },
  mounted() {
    this.loadFilters();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        let params = this.getParams({});
        this.$emit("filters", params);
      },
    },
    "filters.state"(v) {
      this.getCities(v);
    },
  },
};
</script>

<template>
  <div>
    <div class="filters-grid">
      <div class="">
        <div class="form-group mb-0">
          <label>Field MR</label>
          <multiselect
            v-model="filters.telecaller"
            :close-on-select="false"
            :options="telecallers"
            :show-labels="false"
            track-by="id"
            label="username"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="">
        <div class="form-group mb-0">
          <label>Project</label>
          <multiselect
            v-model="filters.project"
            :close-on-select="false"
            :options="projects"
            :show-labels="false"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="">
        <div class="form-group mb-0">
          <label>Speciality</label>
          <multiselect
            v-model="filters.speciality"
            :options="specialities"
            :show-labels="false"
            :close-on-select="false"
            track-by="title"
            label="title"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="">
        <div class="form-group mb-0">
          <label>Product Call Statuses</label>
          <multiselect
            v-model="filters.call_statuses"
            :options="statuses"
            :show-labels="false"
            :close-on-select="false"
            track-by="title"
            label="display_key"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="">
        <div class="form-group mb-0">
          <label>Date Range</label>
          <br />
          <date-picker
            v-model="filters.daterange"
            range
            append-to-body
            lang="en"
            confirm
          ></date-picker>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 18px">
      <div class="col-md-12">
        <div class="d-flex align-items-center justify-content-end">
          <div style="text-decoration: underline; font-size: 16px; cursor: pointer" @click="added">
            {{ clicked ? "Less Filters" : "More Filters" }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="isHidden">
      <div class="filters-grid">
        <div class="">
          <div class="form-group mb-0">
            <label>State</label>
            <multiselect
              v-model="filters.state"
              :options="states"
              :close-on-select="false"
              :show-labels="false"
              track-by="name"
              label="name"
              :multiple="true"
            ></multiselect>
          </div>
        </div>
        <div class="">
          <div class="form-group mb-0">
            <label>City</label>
            <multiselect
              v-model="filters.city"
              :options="cities"
              :close-on-select="false"
              :show-labels="false"
              track-by="name"
              label="name"
              :multiple="true"
            ></multiselect>
          </div>
        </div>
      </div>
    </template>
  </div>
  
</template>


<style scoped>
.filters-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
    margin-bottom: 18px;
}


</style>